import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import SEO from "../../components/seo";
import Skel from "../../components/skel";

const Integration = ({ title, description, link }) => {
  return (
    <Row style={{ marginBottom: 30 }}>
      <Col md={4} className="text-right">
        <h3>{title}</h3>
      </Col>
      <Col md={8}>
        <p className="lead">{description}</p>
        {link ? (
          <a class="m-btn m-btn-theme2nd m-btn-radius" href={link}>
            Learn More &raquo;
          </a>
        ) : null}
      </Col>
    </Row>
  );
};

const IntegrationsPage = () => (
  <Skel>
    <SEO title="Integrations supported by Taplist.io" />

    <main>
      <section className="section gray-bg" style={{ padding: "1.5em" }}>
        <Container>
          <Row className="md-m-25px-b justify-content-center text-center">
            <Col lg={12}>
              <h3 className="h1 m-15px-b">Better Together</h3>
              <p style={{ marginBottom: 0 }}>
                Taplist.io is not just a generic menu system; we designed it to
                work great with a bunch of beverage and menu tools you may
                already be using.
              </p>
            </Col>
          </Row>
        </Container>
      </section>

      <section style={{ marginTop: 30, marginBottom: 30 }}>
        <Container>
          <Integration
            title={"Kegtron Pro"}
            description={
              "Our direct Kegtron Pro integration will automatically synchronize your keg states and show current keg levels."
            }
            link={"/integrations/kegtron"}
          />
          <Integration
            title={"Plaato Keg"}
            description={
              "Use Plaato Keg with any corny keg to synchronize and show keg levels on Taplist.io."
            }
            link={""}
          />
          <Integration
            title={"Brewfather"}
            description={
              "For homebrewers, import batch and beverage data directly into your Taplist.io database."
            }
            link={""}
          />
          <Integration
            title={"Brewer's Friend"}
            description={
              "For homebrewers, import batch and beverage data directly into your Taplist.io database."
            }
            link={""}
          />{" "}
        </Container>
      </section>
    </main>
  </Skel>
);

export default IntegrationsPage;
